
import 'lazysizes'
import 'lazysizes/plugins/blur-up/ls.blur-up'
import { mapState } from 'vuex'
import util from '~/assets/js/util.mjs'

export default {
  data() {
    return {
      title: "Mécanika'Swing",
      clipped: false,
      drawer: null,
      fixed: false,
      activeNav: null,
      contactEmail: util.getApsEmail(),
      contactPhone: util.getApsPhone(),
      // miniVariant: false,
      // right: true,
      // rightDrawer: false,
      // icons: ['mdi-home', 'mdi-email', 'mdi-calendar', 'mdi-delete'],
      items: [
        {
          title: 'Hello',
          tooltip: 'Bienvenue sur notre site !',
          to: '#intro',
        },
        // {
        //   title: 'Évènements',
        //   tooltip: 'Ca se passe bientôt !',
        //   to: '#evenements',
        // },
        {
          title: 'Cours réguliers',
          tooltip: 'Toutes les informations sur les cours réguliers',
          to: '#classes',
        },
        {
          title: 'Planning',
          tooltip: 'Planning des cours réguliers',
          to: '#planning',
          sub: true,
        },
        // {
        //   title: 'Sessions thématiques',
        //   tooltip: 'Sessions thématiques du mardi',
        //   to: '#session-thematique',
        //   sub: true,
        // },
        {
          title: 'Description des cours',
          tooltip: 'Description des cours',
          to: '#description-cours',
          sub: true,
        },
        {
          title: 'Tarifs',
          tooltip: 'Tarifs des cours réguliers',
          to: '#price',
          sub: true,
        },
        // {
        //   title: 'FAQ',
        //   tooltip: 'Vous avez des questions ?',
        //   to: '#classes-faq',
        //   sub: true,
        // },
        {
          title: 'Retrospective 2023-24',
          tooltip: 'Tous ce que nous avons fait la saison dernière !',
          to: '#saison-2023-24',
        },
        {
          title: 'Qui sommes-nous ?',
          tooltip: "Nous vous présentons l'équipe !",
          to: '#teachers',
        },
        // {
        //   title: 'Stages',
        //   tooltip: 'Section Stages',
        //   to: '#workshops',
        // },
        // {
        //   title: "Quoi d'autres ?",
        //   tooltip: 'Les autres activités que nous proposons',
        //   to: '#projects',
        // },
        {
          title: 'Nous contacter',
          tooltip: 'Vous voulez nous contacter ?',
          to: '#contact',
        },
      ],
    }
  },
  computed: {
    ...mapState({
      currentSection: (state) => state.navigation.section,
    }),
  },
  watch: {
    currentSection(newCurrent) {
      const hash = '#' + encodeURIComponent(newCurrent)

      const items = this.items
      for (const key in items) {
        const item = items[key]
        item.active = item.to === hash

        if (item.active) {
          this.activeNav = key
        }
      }

      // console.log('watch currentSection', items, hash)

      history.pushState({}, null, this.$route.path + hash)
      // this.$router.replace(hash)
    },
  },
  // mounted() {
  //   this.$nextTick(function() {
  //     // Code that will run only after the
  //     // entire view has been rendered
  //
  //     // eslint-disable-next-line no-console
  //     console.log('Hooray, Nuxt.js app mounted.')
  //
  //     // object-fit polyfill run
  //     objectFitImages()
  //     /* init Jarallax */
  //     jarallax(document.querySelectorAll('.jarallax'))
  //     jarallax(document.querySelectorAll('.jarallax-keep-img'), {
  //       keepImg: true
  //     })
  //
  //     jarallaxElement()
  //     jarallaxVideo()
  //   })
  // }
}
